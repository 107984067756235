import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { capitalizeFirstLetter, getClassCApps } from "./helpers";
import MenuContext from "./ContextProviders/MenuContext";
import MobileOpenSideNav from "./MobileOpenSideNav";
import { Flex, Text } from "@radix-ui/themes";

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<any>({
        width: undefined,
        height: undefined,
    });

    function handleResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", handleResize);

            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);
    return windowSize;
}


interface ChildLink {
    href: string;
    label: string;
}

interface ParentLink { 
    href: string; 
    label: string; 
    items: ChildLink[]; 
}

export default function SideNav() {
    const router = useRouter();
    const handleGuides = () => {
        const resp = require("../data/dato-guides.json");
        const grouping: ParentLink[] = [];
        resp.data.allGuides.filter((x) => {
            return (x.grouping !== "increment" && x._status === "published");
        }).forEach((item) => {
            const listItem = {
                href: `/docs/unified-api/guides/${item.slug}/`,
                label: `${item.title}`
            };
            if (!grouping.some((i) => i.label === item.grouping)) {
                grouping.push({ href: "", label: item.grouping, items: [listItem] });
            } else {
                const currentGroupingIndex = grouping.findIndex((i) => i.label === item.grouping);
                const currentGroup = grouping[currentGroupingIndex];
                currentGroup.items = [...currentGroup.items, listItem];
                grouping[currentGroupingIndex] = currentGroup;
            }
        });

        return grouping;
    };

    const handleClassC = () => {
        const resp = require("../data/class-c-lite.json");

        const apps: any = getClassCApps(resp)
            .map((x) => {
                return {
                    href: `/docs/unified-api/class-c/${x.slug}/`,
                    label: capitalizeFirstLetter(x.slug)
                };
            });
        return apps;
    };

    const [guides] = useState(handleGuides());
    const [classC] = useState(handleClassC());
    
    const [nav] = useState<any[]>([
        {
            title: "Get started",
            topRoute: "/docs",
            links: [
                { href: "/docs", label: "Overview" },
                { href: "/docs/glossary", label: "Glossary" },
            ],
        },
        {
            title: "Unified API",
            topRoute: "/docs/unified-api",
            links: [
                { href: "/docs/unified-api", label: "Overview" },
                { href: "/docs/unified-api/setup/", label: "Setup" },
                { href: "quickstart", label: "Quickstart" , items: [
                    { href: "/docs/unified-api/quickstart/typescript-sdk/", label: "Typescript SDK" },
                    { href: "/docs/unified-api/quickstart/goldrush-templates/", label: "GoldRush Templates" },
                    { href: "/docs/unified-api/quickstart/goldrush-kit/", label: "GoldRush Kit" },
                    { href: "/docs/unified-api/quickstart/goldrush-decoder/", label: "GoldRush Decoder" },

                ]},
                { href: "/docs/unified-api/concepts/", label: "API Concepts" },
                { href: "/docs/unified-api/catalog/", label: "API Catalog" },
                { href: "/docs/unified-api/sdk/", label: "SDKs", items: [
                    { href: "/docs/unified-api/sdk/typescript/", label: "TypeScript" },
                    { href: "/docs/unified-api/sdk/python/", label: "Python" },
                    { href: "/docs/unified-api/sdk/go/", label: "Go" },
                ]},
                { href: "/docs/unified-api/pricing/", label: "Pricing & Rates Card" },
                { href: "/docs/unified-api/premium/", label: "Premium User Guide" },
                {
                    href: "class-c", label: "Class C", items: [
                        { href: "/docs/unified-api/class-c/", label: "Overview" },
                        ...classC
                    ]
                },
                {
                    href: "goldrush", label: "GoldRush Kit", items: [
                        { href: "/docs/unified-api/goldrush/kit/gold-rush-provider/", label: "<GoldRushProvider />" },
                        { href: "/docs/unified-api/goldrush/kit/token-balances-list-view/", label: "<TokenBalancesList />" },
                        { href: "/docs/unified-api/goldrush/kit/nft-wallet-tokens-list-view/", label: "<NFTWalletCollectionList />" },
                        { href: "/docs/unified-api/goldrush/kit/address-activity-list-view/", label: "<AddressActivityList />" },
                        { href: "/docs/unified-api/goldrush/kit/token-transfers-list-view/", label: "<TokenTransfersList />" },

                    ]
                },
                {
                    href: "guides", label: "Guides", items: [
                        { href: "/docs/unified-api/guides", label: "Overview" },
                        ...guides.map((it) => {
                            if (it.label === "nft") {
                                it.label = "NFT";
                            }
                            if (it.label === "transactions") {
                                it.label = "Transactions";
                            }
                            if (it.label === "account abstraction") {
                                it.label = "Account Abstraction";
                            }
                            if (it.label === "balances") {
                                it.label = "Balances";
                            }
                            if (it.label === "other") {
                                it.label = "Other";
                            }
                            return it;
                        })
                    ]
                },
                { href: "/docs/unified-api/data-coverage", label: "Data Coverage" },
                { href: "/docs/unified-api/changelog", label: "Changelog" },
                { href: "/docs/unified-api/faq/", label: "FAQ" },
            ],
        },
    ]);

    
    const menuContext: any = useContext(MenuContext);
    const topRouteParts = router.pathname.split("/");
    let topRoute = `/${topRouteParts[1]}${topRouteParts[2] ? "/" + topRouteParts[2] : ""}`;
    
    if (topRoute === "/docs" || topRoute === "/docs/glossary") {
        topRoute = "/docs";
    }


    return (
        <nav className={"sidenav" + (menuContext && !menuContext.menuIsOpen ? " closed" : "")}>
            <MobileOpenSideNav/>
            {
                nav.filter((i) => i.topRoute === topRoute)[0].links.map((item, index) => {
                    return <Item key={item.href + index} item={item} />;
                })
            }
            <style jsx>
                {`
                nav {
                    position: sticky;
                    top: 0;
                    height: calc(100vh - var(--top-nav-height));
                    overflow-x: hidden;
                    flex: 0 0 250px;
                    overflow-y: auto;
                    padding: 2.5rem 1rem 2rem 1rem;
                }

                .nested-items {
                    margin-left: 1.7rem;
                }

                @media screen and (max-width: 767px) {
                    nav.closed {
                        display: none;
                    }

                    nav {
                        position: fixed;
                        background: var(--code-background);
                        height: 100vh;
                        width: 100vw;
                        z-index: 20;
                        padding-top: var(--top-nav-height);
                        padding-bottom: calc(var(--top-nav-height) + 2rem);
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }

                    nav.open {
                        display: block;
                        position: fixed;
                        z-index: 20;
                        background: var(--background);
                        width: 100%;
                        top: var(--top-nav-height);
                        height: calc(100vh - var(--top-nav-height));
                    }

                    .dark nav.open {
                        background: var(--text);
                    }
                }
                `}
            </style>
        </nav>
    );
}

export const ListItem = ({link, scroll, active, nested = false, show = true}) => {
    return <React.Fragment>
        <li className={(active ? " active " : "") + (nested ? " nested " : "") + (show !== true && show === false && nested ? " hide " : " show ")}>
            <Link href={link.href} scroll={scroll}>
                <Text size="2" color={active ? undefined : "gray"}>
                    {link.label}
                </Text>
            </Link>
        </li>
        <style jsx>
            {`
            li :global(a:hover),
            li.active > :global(a) {
                text-decoration: none;
                background: var(--code-background);
                padding: 0.5rem 0.75rem;
                width: max-content;
                border-radius: calc(var(--border-radius) * 2);
                transition: 0.2s;
                display: block;
                max-width: 100%;
            }
            li {
                list-style-type: none;
                font-size: var(--font-size-2);
                text-overflow: ellipsis;
                font-size: var(--font-size-2);
                padding: 0rem 0;
            }
            li :global(a) {
                text-decoration: none;
                background: var(--background);
                padding: 0.5rem 0.75rem;
                width: max-content;
                border-radius: calc(var(--border-radius) * 2);
                transition: 0.2s;
                display: block;
                max-width: 100%;
            }
            li.nested {
                margin-left: 1.75rem;
                width: 100%;
                overflow: hidden;
                padding-right: 2rem;
            }
            li.hide {
                display: none;
            }
            @media screen and (max-width: 767px) {
                li {
                    font-size: var(--font-size-2);
                    padding: 0.5rem 0;
                }
            }
        `}
        </style>
    </React.Fragment>;
};

const Item = ({item}) => {
    const router = useRouter();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (router.asPath.includes(item.href) && item.href) {
            setOpen(true);
        } else if (item.href) {
            setOpen(false);
        }
    }, [router.asPath]);

    const handleClick = () => {
        setOpen(!open);
    };

    const href = item.href.includes("/") ? item.href : false;

    return <React.Fragment>
        <Flex mt="1" mb="1" className={router.asPath === href ? "current" : "not-current"} align="center" gap="2" style={{borderRadius: "calc(var(--border-radius) * 2)", fontSize: "var(--font-size-2)", maxWidth: "max-content"}} p="2">
            {href ? <Link className="nav-link" href={href}>
                <Text size="2" color={router.asPath === href ? undefined : "gray"}>{item.label}</Text>
            </Link> : <span onClick={handleClick} className="nav-link">
                <Text size="2" color={router.asPath === href ? undefined : "gray"}>{item.label}</Text>
            </span>}
            {item.items ? <span onClick={handleClick} className="material-symbols-rounded">{open ? "expand_less" : "expand_more"}</span> : null}
        </Flex>
        {item.items ? <div className={open ? "open" : "closed"}>{item.items.map((i) => <Item key={i.href} item={i}/>)}</div> : null}
        <style jsx>
            {`


                .material-symbols-rounded {
                    font-size: var(--font-size-4);
                    cursor: default;
                }

                .open {
                    
                }

                :global(.nav-link) {
                    text-decoration: none;
                    cursor: pointer;
                }

                :global(a.nav-link:hover) {
                    text-decoration: underline;
                }

                .closed {
                    display: none;
                }

                div {
                    margin-left: 1rem;
                    cursor: pointer;
                }

                div div {
                    margin-left: 1rem;
                }
            `}
        </style>
    </React.Fragment>;
};

export const ListItemDivider = ({link}) => {
    return <React.Fragment>
        <li className="divider">
            <Text size="2">{link.label}</Text>
        </li>
        <style jsx>
            {`
            li.divider {
                color: var(--text);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 1em;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                position: relative;
                font-weight: 600;
            }
            li.divider:first-of-type {
                margin-top: 0;
            }
            li {
                list-style-type: none;
                font-size: var(--font-size-2);
                text-overflow: ellipsis;
                font-size: var(--font-size-2);
                padding: 0.5rem 0;
            }
            @media screen and (max-width: 767px) {
                li {
                    font-size: var(--font-size-2);
                    padding: 0.5rem 0;
                }
            }
        `}
        </style></React.Fragment>;
};

export const ListItemParent = ({link, hc, isActive, id, show = true, nested = false, isSubActive = null}: {link: any, hc: any, isActive: any, id: any, show?: boolean, nested?: boolean, isSubActive?: boolean | null}) => {
    let label;
    if (link.label === "Avalanche C-Chain") {
        label = "Avalanche";
    } else if (link.label === "xyk") {
        label = "XY=K";
    } else {
        label = link.label;
    }
    return <React.Fragment>
        <li id={id} onClick={hc} className={" parent " + (show ? " show " : " hide ") + (nested ? " nested " : "")}>
            <div className="item">
                <Text size="2" color={isActive && (isSubActive === null || isSubActive === true) ? undefined : "gray"}>{label}</Text>
            </div>
            <div className="icon">
                {isActive && (isSubActive === null || isSubActive === true) ? <span className="small-icon material-symbols-rounded">expand_less</span> : <span className="small-icon material-symbols-rounded">expand_more</span>}
            </div>
        </li>
        <style jsx>
            {`
            .small-icon {
                opacity: 0.3;
                font-size: 15px;
            }
            li {
                list-style-type: none;
                font-size: var(--font-size-2);
                text-overflow: ellipsis;
                padding: 0.5rem 0;
            }
            li :global(a) {
                text-decoration: none;
            }
            li :global(a:hover),
            li.active > :global(a) {
                text-decoration: none;
            }
            li.nested {
                margin-left: 0.75rem;
                width: max-content;
                max-width: 100%;
                padding-right: 2rem;
            }
            li.hide {
                display: none;
            }
            .parent {
                cursor: pointer;
                display: flex;
                gap: 0.5rem;
                position: relative;
                text-transform: capitalize;
                background: var(--background);
                padding: 0.5rem 0.75rem;
                width: max-content;
                max-width: 100%;
                border-radius: calc(var(--border-radius) * 2);
                transition: 0.2s;
            }
            .parent:hover {
                text-decoration: none;
                background: var(--code-background);
                padding: 0.5rem 0.75rem;
                width: max-content;
                max-width: 100%;
                border-radius: calc(var(--border-radius) * 2);
                transition: 0.2s;
            }
            .parent.sub.hide {
                display: none;
            }
            .parent.sub {
                margin-left: 1rem;
            }
            li.nested.parent {
                margin-left: 1.5rem;
                width: calc(100% - 1.75rem);
                padding-right: 0;
            }
            .parent .icon {
                position: block;
                display: flex;
                place-items: center;
                justify-content: center;
            }
            @media screen and (max-width: 767px) {
                li {
                    font-size: var(--font-size-2);
                    padding: 0.5rem 0;
                }
                .parent.show {
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }
            }
        `}
        </style>
    </React.Fragment>;
};
import React, { useEffect, useState, ReactNode } from "react";
import Head from "next/head";
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Toaster from "../components/Toaster";
import "../styles/output.css";
import type { MarkdocNextJsPageProps } from "@markdoc/next.js";
import { None, Option, Some } from "../components/helpers/option";
import '@radix-ui/themes/styles.css';
import "prismjs";
import "prismjs/components/prism-bash.min";
import "prismjs/components/prism-ruby.min";
import "prismjs/components/prism-python.min";
import "prismjs/components/prism-sql.min";
import "prismjs/components/prism-json.min";
import "prismjs/components/prism-typescript.min";
import "prismjs/components/prism-solidity.min";
import "prismjs/components/prism-go.min";
import "prismjs/themes/prism.css";
import { useRouter } from "next/router";
import { rudderStackLocation } from "../components/Track";
import * as Toast from "@radix-ui/react-toast";
import { api, IAuthUser, PLATFORM_ROUTE, mountHelpScout, deleteAllCookies, sortUserFirst } from "../components/helpers";
import { cookie_read } from "../components/helpers/cookies";
import { useScrollReset } from "../components/hooks/useScrollReset";
import MenuContext from "../components/ContextProviders/MenuContext";
import LanguageContext from "../components/ContextProviders/LanguageContext";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Layout from "../components/layouts/DatoDocsLayout";
import BannerImage from "../components/BannerImage";
import { Theme } from "@radix-ui/themes";
import TurnstileContext from "../components/ContextProviders/TurnstileContext";
import { useTurnstile } from "../components/hooks/useTurnstile";
import { ThemeContext, useTheme } from "../components/ContextProviders/ThemeContext";
import { Transition } from "../components/pages/Transition";

// eslint-disable-next-line @typescript-eslint/ban-types
type Page<P = {}> = NextPage<P> & {
    getLayout?: (page: ReactNode) => ReactNode;
};

type PageType = "home"
| "blog"
| "blogPost"
| "companyBlog"
| "productBlog"
| "ecosystemBlog"
| "incrementBlog"
| "legacyBlogPost"
| "landingPage"
| "productPage"
| "networkDocs"
| "covalentNetworkDocs"
| "networkDocsPage"
| "networkDocsMisc"
| "apiGuides"
| "apiGuidesPage"
| "defaultDocs"
| "apiDocs"
| "event"
| "api"
| "default"
| "platform"
| "markDocDefault"
| "replace"
| "markDocNetworkDocs"
| "catalog"
| "changeLog"
| "application"
| "balances"
| "dataCoverage"
| "classC"
| "unifiedAPIDocs"
| "sdks"
| "markDocIncrementDocs"
| "goldrush"
| "";

type Condition = {
    condition: () => boolean;
    result: PageType;
};

type Props = AppProps & {
    Component: Page;
};

const TITLE = "Covalent";
const DESCRIPTION = "One unified API. One billion possibilities.";
export type MyAppProps = MarkdocNextJsPageProps | any;

const MyApp = ({ Component, pageProps }: Props) => {
    const getLayout = Component.getLayout ?? ((page: ReactNode) => page);
    const router = useRouter();
    const { markdoc } = pageProps;
    const [mobileMenuIsOpen, setMobileMenu] = React.useState(false);
    const [user, setUser] = useState<Option<IAuthUser>>(None);
    const [token, setToken] = useState<string>();
    const [landingPage, setLandingPage] = useState<Option<string>>(None);
    const [resetUser, setResetUser] = useState();
    const [busy, setBusy] = useState(true);
    const [open, setOpen] = useState(false);
    const [toaster, setToaster] = useState({
        title: <></>,
        description: <></>,
        action: <></>
    });

    const themeFunctions = useTheme();
    const contextFunctions = useTurnstile();
    const [isTransparent, setTransparent] = useState(true);
    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            if (position >= 50 && isTransparent !== false) {
                setTransparent(false);
            } else {
                if(isTransparent !== true) {
                    setTransparent(true);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [visitor, setVisitor] = React.useState({
        id: "",
        visitor_locale: Intl.DateTimeFormat().resolvedOptions().locale,
        visitor_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    const [adBlock, setAdBlockInUse] = React.useState<boolean>(false);
    const DUMMY_VISITOR_ID = "local000000000000000";


    async function getVisitorID() {
        const navigationOrigin = (new URL(location.href)).hostname;
        if (navigationOrigin === "127.0.0.1" || navigationOrigin === "localhost") {
            return Promise.resolve(DUMMY_VISITOR_ID);
        }

        if (localStorage.fpjsVisitorID) {
            return Promise.resolve(localStorage.fpjsVisitorID);
        }

        const fingerprintJSAgentPromise = FingerprintJS.load({
            endpoint: "https://goldrush.dev/2LXH5c16OGBroxOH/5S7GiZ91mOdPAupl",
            scriptUrlPattern: "https://goldrush.dev/2LXH5c16OGBroxOH/9vBN3hphTVmtEE1g?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
            apiKey: "WWJ2c9KNIwarD1Wx9kkK"
        });

        return new Promise((resolve, reject) => {
            fingerprintJSAgentPromise.then(
                (agent) => {
                    agent.get().then(
                        (result) => {
                            if (result.visitorFound) {
                                localStorage.fpjsVisitorID = result.visitorId;
                                resolve(result.visitorId);
                            } else {
                                reject(result);
                            }
                        },
                        (getFailure) => {
                            reject(getFailure);
                        }
                    );
                },
                (agentLoadFailure) => {
                    reject(agentLoadFailure);
                }
            );
        });
    }

    React.useEffect(() => {
        if (isAuth || isPlatform || isAd || isFaucet || isGrant) {
            getVisitorID().then(
                (visitorID) => {
                    const updatedVisitor = { ...visitor, id: visitorID };
                    setVisitor(updatedVisitor);
                },
                (reason) => {
                    if (reason instanceof Error && reason.message === "Not available for this origin") {
                        const updatedVisitor = { ...visitor, id: DUMMY_VISITOR_ID };
                        setVisitor(updatedVisitor);
                    } else {
                        setAdBlockInUse(true);
                    }
                }
            );
        }
    }, [router]);

    const determineType = (): PageType => {
        const conditions: Condition[] = [
            { condition: () => pageProps.component === "HomePage", result: "home"},
            { condition: () => pageProps.component === "CompanyBlog", result: "companyBlog"},
            { condition: () => pageProps.component === "ProductBlog", result: "productBlog"},
            { condition: () => pageProps.component === "EcosystemBlog", result: "ecosystemBlog"},
            { condition: () => pageProps.component === "IncrementBlog", result: "incrementBlog"},
            { condition: () => pageProps.component === "BlogPage", result: "blogPost"},
            { condition: () => pageProps.component === "Blog", result: "blog"},
            { condition: () => pageProps.component === "Catalog", result: "catalog" },
            { condition: () => pageProps.component === "Changelog", result: "changeLog" },
            { condition: () => pageProps.component === "ClassC" && pageProps.post && pageProps.post.application, result: "application"},
            { condition: () => pageProps.component === "ClassC", result: "classC"},
            { condition: () => pageProps.component === "Guide", result: "apiGuidesPage"},
            { condition: () => pageProps.component === "LandingPage", result: "landingPage"},
            { condition: () => pageProps.component === "ProductPage", result: "productPage"},
            { condition: () => pageProps.component === "Networks", result: "networkDocs"},
            { condition: () => pageProps.component === "Network", result: "networkDocsPage"},
            { condition: () => pageProps.component === "DataCoverage", result: "dataCoverage"},
            { condition: () => pageProps.component === "Guides", result: "apiGuides"},
            { condition: () => pageProps.component === "SDKHome" || pageProps.component === "SDK", result: "sdks"},
            { condition: () => pageProps.component === "Documentation", result: "defaultDocs"},
            { condition: () => pageProps.component === "Platform", result: "platform"},
            { condition: () => pageProps.component === "GRK", result: "goldrush"},
            { condition: () => markdoc?.file?.path.startsWith("/blog/"), result: "legacyBlogPost"},
            { condition: () => markdoc?.file?.path.startsWith("/docs/networks"), result: "markDocNetworkDocs" },
            { condition: () => markdoc?.file?.path.startsWith("/docs/increment"), result: "markDocIncrementDocs" },
            { condition: () => markdoc?.file?.path.startsWith("/docs/unified-api"), result: "unifiedAPIDocs"},
            { condition: () => markdoc?.file?.path.startsWith("/docs/covalent-network"), result: "covalentNetworkDocs"},
            { condition: () => markdoc?.frontmatter?.title && markdoc?.frontmatter?.description, result: "markDocDefault"},
        ];

        for (const { condition, result } of conditions) {
            if (condition()) {
                return result;
            }
        }

        return "";
    };

    const CHAIN_COUNT = "200";

    const getTitleDescription = () => {
        const descriptions = {
            home: () => pageProps.content.meta.description,
            blog: () => "Latest news, events, product and corporate updates.",
            companyBlog: () => "Latest corporate updates.",
            productBlog: () => "Latest product updates.",
            ecosystemBlog: () => "Latest ecosystem updates.",
            incrementBlog: () => "Latest blockchain data analytics updates.",
            legacyBlogPost: () => markdoc.frontmatter.description,
            unifiedAPIDocs: () => markdoc.frontmatter.description,
            blogPost: () => pageProps.blog.seo.description,
            landingPage: () => pageProps.content.meta.description,
            productPage: () => pageProps.content.description,
            networkDocs: () => "Support for Class A, Class B, Class C, NFTs, Human Decoded Transactions.",
            networkDocsPage: () => `Rich, granular and archival data for the ${pageProps.network.displayname} blockchain including token balances, transactions and NFTs.`,
            networkDocsMisc: () => `Documentation for the Covalent ${CHAIN_COUNT}+ supported networks.`,
            apiDocs: () => `With over ${CHAIN_COUNT} chains currently supported, Covalent's breadth of chain coverage is unparalleled. Whether it's an event, a token or a transaction, Covalent captures it in real-time.`,
            apiGuides: () => "The GoldRush Guides show you how to solve a specific problem or use case using the GoldRush API.",
            apiGuidesPage: () => (pageProps.post.seo && pageProps.post.seo.description) ? pageProps.post.seo.description : "",
            defaultDocs: () => `Documentation for the Covalent ${CHAIN_COUNT}+ supported networks, products and services, including the GoldRush data tools.`,
            platform: () => "Register, Login and Get a free API Key for the GoldRush API.",
            markDocNetworkDocs: () => markdoc.frontmatter.description || `Rich, granular and archival data for the ${markdoc.frontmatter.title.replace("Blockchain Data Indexing API", "").trim()} blockchain including token balances, transactions and NFTs.`,
            markDocIncrementDocs: () => markdoc.frontmatter.description,
            markDocDefault: () => markdoc.frontmatter.description,
            catalog: () => "Comprehensive list of every single API on the Covalent platform.",
            changeLog: "New features, bug fixes, and improvements to the Covalent platform.",
            application: () => `Rich, granular and archival data for the ${pageProps.post.application.displayname} protocol including protocol balances, transactions and market data.`,
            classC: () => "Class C endpoints extend the capability and coverage of the Covalent platform with community maintained endpoints.",
            dataCoverage: () => `With over ${CHAIN_COUNT}+ chains currently supported, Covalent's breadth of chain coverage is unparalleled. Whether it's an event, a token or a transaction, Covalent captures it in real-time.`,
            sdks: () => "Official open-source client libraries for your favorite platforms.",
            goldrush: () => pageProps.grk.description

        };

        const titleTag = "GoldRush - powered by Covalent";

        const titles = {
            home: () => `${pageProps.content.meta.title} | ${titleTag}`,
            blog: () => `Blog | ${titleTag}`,
            companyBlog: () => `Company Blog | ${titleTag}`,
            productBlog: () => `Product Blog | ${titleTag}`,
            ecosystemBlog: () => `Ecosystem Blog | ${titleTag}`,
            incrementBlog: () => `Increment Blog | ${titleTag}`,
            blogPost: () => `${pageProps.blog.seo.title} | ${titleTag}`,
            landingPage: () => `${pageProps.content.meta.title} | ${titleTag}`,
            productPage: () => `${pageProps.content.title} | ${pageProps.content.category} | ${titleTag}`,
            networkDocs: () => `Integration Support for Blockchain Networks | ${titleTag}`,
            networkDocsPage: () => `${pageProps.network.displayname} Blockchain Data Indexing API | ${titleTag}`,
            networkDocsMisc: () => `${CHAIN_COUNT}+ Supported Blockchain Networks | ${titleTag}`,
            apiDocs: () => `Data Coverage | GoldRush API | ${titleTag}`,
            dataCoverage: () => `Data Coverage | GoldRush API | ${titleTag}`,
            unifiedAPIDocs: () => `${markdoc?.frontmatter?.pageTitle || markdoc?.frontmatter?.title || pageProps?.post?.title || pageProps.content.title} | ${titleTag}`,
            covalentNetworkDocs: () => `${markdoc?.frontmatter?.pageTitle || markdoc?.frontmatter?.title || pageProps.post.title}`,
            apiGuides: () => `Guides | GoldRush API | ${titleTag}`,
            apiGuidesPage: () => `${pageProps.post.title}  | GoldRush API | ${titleTag}`,
            defaultDocs: () => `Documentation Home | ${titleTag}`,
            platform: () => `Platform | ${titleTag}`,
            legacyBlogPost: () => markdoc?.frontmatter?.pageTitle || `${markdoc.frontmatter.title} | ${titleTag}`,
            markDocNetworkDocs: () => markdoc?.frontmatter?.pageTitle || `${markdoc.frontmatter.title} | ${titleTag}`,
            markDocIncrementDocs: () => markdoc?.frontmatter?.pageTitle || `${markdoc.frontmatter.title} | ${titleTag}`,
            markDocDefault: () => markdoc?.frontmatter?.pageTitle || `${markdoc.frontmatter.title} | ${titleTag}`,
            catalog: () => `API Catalog | GoldRush API | ${titleTag}`,
            changeLog: () => `API Changelog | GoldRush API | ${titleTag}`,
            application: () => `${pageProps.post.application.displayname} Wallet Balances and Transactions | GoldRush API | ${titleTag}`,
            classC: () => `Class C | GoldRush API | ${titleTag}`,
            sdks: () => `Developer SDKs | GoldRush API | ${titleTag}`,
            goldrush: () => `${pageProps.grk.title} | GoldRush Kit | ${titleTag}`
        };
        const type = determineType();
        const title = typeof titles[type] === "function" ? titles[type]() : TITLE;
        const description = typeof descriptions[type] === "function" ? descriptions[type]() : DESCRIPTION;
        return [title, description];
    };

    const [title, description] = getTitleDescription();

    let ogImage: any = null;
    if (pageProps.component === "Guide" || pageProps.component === "BlogPage") {
        const post = pageProps.post || pageProps.blog;
        if (post.bannerimagegraphic) {
            ogImage = `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title)}&subtitle=guides`;
        } else if (post.bannerimage && post.bannerimage.url) {
            ogImage = post.bannerimage.url;
        }
    }

    const isLegacy = pageProps.markdoc?.frontmatter?.type === "legacy";

    useEffect(() => {
        setMobileMenu(false);
    }, [pageProps]);

    const handleAuth = async () => {
        const cookie = cookie_read("cov_token");

        if (cookie) {
            const resp = await api.auth_covalent(cookie ? cookie : "");
            if (!resp || resp.error) {
                setUser(None);
                setLandingPage(None);
                deleteAllCookies();
                return;
            }
            if (resp) {
                setUser(new Some(resp.data.user));
                if (resp.data.landing_page) {
                    setLandingPage(new Some(resp.data.landing_page));
                }
                setToken(resp.data.token);
            }
            setBusy(false);
        } else {
            setUser(None);
            setLandingPage(None);
            setBusy(false);
        }
    };

    interface Toast {
        title?: string;
        description?: string;
        action?: any;
        error: boolean;
        error_message?: string
    }

    const handleToast = (props: Toast) => {
        setOpen(true);

        if (props.error) {
            setToaster({
                title: <span className="ToastFail">An error has occured</span>,
                description: <div>{props.error_message ? props.error_message : "Please try again."}</div>,
                action: props.action
            });
        } else {
            setToaster({
                title: <span className="ToastSuccess">{props.title}</span>,
                description: <div>{props.description}</div>,
                action: props.action
            });
        }

    };

    useEffect(() => {
        rudderStackLocation();
        handleAuth();
    }, []);

    const [usage, setUsage]: any = React.useState(None);
    const [apikeys, setKeys]: any = React.useState(None);

    useEffect(() => {
        user.match({
            None: () => mountHelpScout(false),
            Some: async (user) => {
                if (user && user.group) {
                    // mountHelpScout(user.group.is_paid);
                    if (token) {
                        const credits: any = await api.getCreditUsageForAccount(token);
                        if (credits || !credits.error) {
                            const item = credits.data.item[0];
                            setUsage(new Some(item));
                        }
                        const keys: any = await api.get_all_api_keys(token);
                        if (keys || !credits.error) {
                            const apikeys = keys.data.items.sort((a, b) => {
                                return sortUserFirst(a, b, user.id);
                            });
                            setKeys(new Some(apikeys));
                        }
                    }
                }
            }
        });
    }, [user]);

    let legacyFrontMatter;
    if (isLegacy) {
        legacyFrontMatter = pageProps.markdoc?.frontmatter;
        legacyFrontMatter.legacy = true;
    }

    const isPolicy = pageProps.markdoc?.frontmatter?.type === "policy";
    let policyFrontMatter;
    if (isPolicy) {
        policyFrontMatter = pageProps.markdoc?.frontmatter;
        policyFrontMatter.policy = true;
    }

    const isPlatform = router.asPath.startsWith(`/${PLATFORM_ROUTE}`);
    const isAuth = router.asPath.startsWith(`/${PLATFORM_ROUTE}/auth`);
    const isAd = router.asPath.startsWith("/lp/") || router.asPath.startsWith("/lps/");
    const isFaucet = router.asPath.startsWith("/faucet/");
    const isGrant = router.asPath.startsWith("/grant/");
    useScrollReset();

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleChangeMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    React.useEffect(() => {
        setMenuIsOpen(false);
    }, [router.events]);

    const [date,setDate] = useState("");

    useEffect(()=>{
        if (pageProps.markdoc) {
            setDate(new Date(pageProps.markdoc?.frontmatter.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
            }));
        }
    },[]);

    const [language, setLanguage] = React.useState("TypeScript");

    if (markdoc) {
        const path = pageProps.markdoc.file.path.replace("index", "").replace(".md", "/").replace("//", "/");
        return <>
            <Head>
                <title>{title}</title>
                <meta name="theme-color" content="#000426" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="referrer" content="strict-origin" />
                <meta name="title" content={`${title}`} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta name="og:description" content={description} />
                <meta name="og:image" content={ogImage ? ogImage : `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title.replace("| Covalent", ""))}`} />
                <link
                    rel="canonical"
                    href={`https://www.goldrush.dev${path}`}
                    key="canonical" />
            </Head>
            <ThemeContext.Provider value={themeFunctions}>
                <Toast.Provider swipeDirection="right" duration={8000} >
                    <Head>
                        <title>{title}</title>
                        <meta name="theme-color" content="#000426"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta name="referrer" content="strict-origin" />
                        <meta name="title" content={`${title}`} />
                        <meta name="description" content={description} />
                        <meta property="og:title" content={title} />
                        <meta name="og:description" content={description} />
                        <meta name="og:image" content={ogImage ? ogImage : `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title.replace("| Covalent", ""))}`} />
                        <link
                            rel="canonical"
                            href={`https://www.goldrush.dev${path}`}
                            key="canonical" />
                    </Head>
                    <Toaster title={toaster.title} action={toaster.action} description={toaster.description} open={open} setOpen={setOpen} />
                    <MenuContext.Provider value={{menuIsOpen, handleChangeMenu}}>
                        <LanguageContext.Provider value={{language, setLanguage}}>
                            <Theme
                                accentColor="crimson"
                                radius="medium"
                            >
                                <Layout user={user} mobileMenuIsOpen={mobileMenuIsOpen} setMobileMenu={setMobileMenu} handleToast={handleToast} isLegacy={isLegacy} isPolicy={isPolicy} markdoc={markdoc}>
                                    {isLegacy ? <><div className="legacy-header">
                                        <BannerImage title={pageProps.markdoc?.frontmatter.title} blog={legacyFrontMatter} />
                                        <h1>{pageProps.markdoc?.frontmatter.title}</h1>
                                        <p className="date">{date}</p>
                                    </div></> : null}
                                    <Component {...pageProps}
                                        visitor={visitor}
                                        adBlock={adBlock}
                                        user={user}
                                        setUser={setUser}
                                        setToken={setToken}
                                        setLandingPage={setLandingPage}
                                        landingPage={landingPage}
                                        token={token}
                                        setResetUser={setResetUser}
                                        resetUser={resetUser}
                                        busy={busy}
                                        handleToast={handleToast}
                                        usage={usage}
                                        isTransparent={isTransparent}
                                        setMobileMenu={setMobileMenu}
                                        mobileMenuIsOpen={mobileMenuIsOpen}
                                        userKey={apikeys.match({
                                            None: () => null,
                                            Some: (keys) => keys[0]
                                        })} />
                                </Layout>
                            </Theme>
                        </LanguageContext.Provider>
                    </MenuContext.Provider>
                </Toast.Provider>
            </ThemeContext.Provider>
        </>;
    }

    return getLayout(<>
        <Head>
            <title>{title}</title>
            <meta name="theme-color" content="#000426" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="referrer" content="strict-origin" />
            <meta name="title" content={`${title}`} />
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@Covalent_HQ" />
            <meta name="twitter:creator" content="@Covalent_HQ" />
            <meta property="og:title" content={title} />
            <meta name="og:description" content={description} />
            <meta name="og:image" content={ogImage ? ogImage : `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title.replace("| Covalent", ""))}`} />
        </Head>
        <ThemeContext.Provider value={themeFunctions}>
            <Toast.Provider swipeDirection="right" duration={8000} >
                <TurnstileContext.Provider value={contextFunctions}>
                    <Toaster title={toaster.title} action={toaster.action} description={toaster.description} open={open} setOpen={setOpen} />
                    <MenuContext.Provider value={{ menuIsOpen, handleChangeMenu }}>
                        <LanguageContext.Provider value={{ language, setLanguage }}>
                            <Theme
                                accentColor="crimson"
                                radius="medium"
                            >
                                <Transition theme={themeFunctions.theme}/>
                                <Component {...pageProps}
                                    visitor={visitor}
                                    adBlock={adBlock}
                                    user={user}
                                    setUser={setUser}
                                    setToken={setToken}
                                    setLandingPage={setLandingPage}
                                    landingPage={landingPage}
                                    token={token}
                                    setResetUser={setResetUser}
                                    resetUser={resetUser}
                                    busy={busy}
                                    handleToast={handleToast}
                                    usage={usage}
                                    isTransparent={isTransparent}
                                    setMobileMenu={setMobileMenu}
                                    mobileMenuIsOpen={mobileMenuIsOpen}
                                    userKey={apikeys.match({
                                        None: () => null,
                                        Some: (keys) => keys[0]
                                    })} />
                            </Theme>
                        </LanguageContext.Provider>
                    </MenuContext.Provider>
                    <Toast.Viewport className="ToastViewport" />
                </TurnstileContext.Provider>
            </Toast.Provider>
        </ThemeContext.Provider>
    </>);
};

export default MyApp;

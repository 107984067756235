
const LP_BODY = `
    id
    title
    category
    slug
    description
    productName
    focusedHeroContent
    showAdLandingPage
    revealed
    seo {
      description
      title
    }
    video {
      basename
      video {
        mp4Url
        streamingUrl
      }
    }
    headerbackground {
      image {
        url
      }
    }
    ctaUrl
    ctaText
    headerImage {
      id
      responsiveImage(imgixParams: {fit: crop, w: 450, h: 450, auto: format}) {
        src
        width
        height
        alt
        title
        base64
        bgColor
        sizes
      }  
    }
    headerImagePosition
    endpoints {
      title
      slug
      usecase
      description
      grouping
    }
    content {
      ... on GenericSectionRecord {
        __typename
        id
        hideSectionOnAdLp
        description
        title
        backgroundColour {
          hex
          name
        }
        genericSectionImage {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            src
            width
            height
            alt
            title
            base64
            bgColor
            sizes
          }
        }
      }
      ... on SupportednetworkssectionRecord {
        __typename
      }
      ... on BenefitssectionRecord {
        __typename
        ctaButtonText
        hideSectionOnAdLp
        hideCtaOnAdLp
        ctaUrl
        description
        title
        theme
        videoBackground {
          video {
            mp4Url
          }
        }
        benefitsCards {
          ctaText
          description
          title
          url
          icon {
            id
            responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
              src
              width
              height
              alt
              title
              base64
              bgColor
              sizes
            }  
          }
        }
      }
      ... on CodesamplesectionRecord {
        id
        __typename
        title
        description
        hideSectionOnAdLp
        codeSamples {
          description
          code
          codeType
        }
      }
      ... on MultipleCtaSectionRecord {
        id
        cta {
          description
          id
          title
          hideCtaOnAdLp
          hideSectionOnAdLp
          ctaUrl
          ctaText
        }
      }
      ... on ComingSoonRecord {
        id
        __typename
      }
      ... on CtasectionRecord {
        id
        __typename
        hideSectionOnAdLp
        hideCtaOnAdLp
        title
        description
        ctaText
        ctaUrl
      }
      ... on MultipleCtaSectionRecord {
        cta {
          id
          title
          hideCtaOnAdLp
          hideSectionOnAdLp
          description
          ctaUrl
          ctaText
          createdAt
        }
        theme
        __typename
        id
      }
      ... on TestimonialssectionRecord {
        __typename
        title
        hideSectionOnAdLp
        description
        testimonials {
          name
          title
          organization
          content
        }
      }
      ... on PricingsectionRecord {
        id
        __typename
        title
        description
        ctaText
        hideSectionOnAdLp
        hideCtaOnAdLp
        ctaUrl
        theme
        videoBackground {
          video {
            mp4Url
          }
        }
        pricingTable {
          content
        }
      }
      ... on PartnerssectionRecord {
        id
        __typename
        hideSectionOnAdLp
        partners {
          ... on BlockchainRecord {
            id
            chainname
            chainid
            description
            displayname
            url
            weight
            imgblack {
              id
              responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
                src
                width
                height
                alt
                title
                base64
                bgColor
                sizes
              }    
            }
            imgwhite {
              id
              responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
                src
                width
                height
                alt
                title
                base64
                bgColor
                sizes
              }    
            }
          }
          ... on ClasscRecord {
            id
            imgwhite {
              id
              responsiveImage(imgixParams: {fit: crop, w: "200", h: "200", auto: format}) {
                src
                height
                width
                alt
                title
                sizes
                bgColor
                base64
              }
            }
          }
          ... on ProtocolRecord {
            id
            imgwhite {
              id
              responsiveImage(imgixParams: {fit: crop, w: "200", h: "200", auto: format}) {
                src
                height
                width
                alt
                title
                sizes
                bgColor
                base64
              }
            }
          }
          ... on EcosystemRecord {
            id
            name
            url
            imgwhite {
              id
              responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
                src
                width
                height
                alt
                title
                base64
                bgColor
                sizes
              }    
            }
          }
        }
        description
        title
      }
      ... on UsecasessectionRecord {
        id
        __typename
        title
        description
        mode
        hideSectionOnAdLp
        useCasesCards {
          description
          icon {
            id
            responsiveImage(imgixParams: {fit: fill, auto: format}) {
              src
              width
              height
              alt
              title
              base64
              bgColor
              sizes
            }  
          }
          title
          id
          ctaText
          url
        }
      }
      ... on ImageFeatureSectionRecord {
        id
        __typename
        hideSectionOnAdLp
        hideCtaOnAdLp
        ctaText
        ctaUrl
        description
        theme
        videoBackground {
          video {
            mp4Url
          }
        }
        image {
          responsiveImage(imgixParams: {fit: crop, w: 400, h: 400, auto: format}) {
            src
            width
            height
            alt
            title
            base64
            bgColor
            sizes
          }    
        }
        title
      }
      ... on SignUpSectionRecord {
        id
        __typename
        title
      }
      ... on VideofeaturesectionRecord {
        id
        __typename
        video {
          url    
        }
        title
        description
        hideCtaOnAdLp
        hideSectionOnAdLp
        ctaText
        ctaUrl
      }
      ... on EndpointFeatureSectionRecord {
        id
        __typename
        hideSectionOnAdLp
        endpoint {
          title
          description
          creditratemodel
          slug
        }
        image {
          responsiveImage(imgixParams: {fit: crop, w: 400, h: 400, auto: format}) {
            src
            width
            height
            alt
            title
            base64
            bgColor
            sizes
          }    
        }
        description
      }
      ... on ClassCEndpointFeatureSectionRecord {
        id
        __typename
        hideSectionOnAdLp
        endpoint {
          description
          endpointtype
          id
          name
          params
          samplewallet
          path
          application {
            slug
          }
        }
        image {
          responsiveImage(imgixParams: {fit: crop, w: 400, h: 400, auto: format}) {
            src
            width
            height
            alt
            title
            base64
            bgColor
            sizes
          }    
        }
        description
      }
    }

`;

export const LISTICLES = (limit: number, skip: number) => {
    return `query MyQuery {
      allListiclePages(first: ${limit}, skip: ${skip}) {
      description
      id
      seo {
        description
        title
      }
      slug
      title
      web3Resources {
        categoryTag {
          name
          id
        }
        description
        id
        name
        logo {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            base64
            aspectRatio
            bgColor
            height
            sizes
            src
            webpSrcSet
            title
            webpSrcSet
            width
          }
        }
        shortDescription
        url
        seo {
          description
          title
        }
        chains {
          chainid
          chainname
          displayname
          id
          imgsvg {
            url
            alt
          }
        }
      }
    }
  }`;
};

export const AIRDROPS = `query MyQuery {
  airdropPage {
    title
    description
    faq {
      blocks
      links
      value
    }
    airdrops {
      createdAt
      end
      id
      live
      revealed
      start
      title
      logo {
        responsiveImage {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          srcSet
          title
          webpSrcSet
          width
        }
      }
    }
  }
}`;

export const WEB3_RESOURCES = (limit: number, skip: number) => {
    return `query MyQuery {
    allWeb3Resources(first: ${limit}, skip: ${skip}) {
      categoryTag {
        id
        name
      }
      chains {
        imgsvg {
          url
        }
        chainname
        chainid
        displayname
      }
      description
      id
      logo {
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          base64
          aspectRatio
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
      }
      name
      seo {
        description
        title
        twitterCard
      }
      shortDescription
      url
    }
  }`;
};

export const GET_ALL_LPS = (limit: number, skip: number) => {
    return `query MyQuery {
        allLandingPages(orderBy:title_ASC, first: ${limit}, skip: ${skip})
        {
              ${LP_BODY}
        }
}`;

};

export const GET_ALL_GLOSSARIES = (limit: number, skip: number) => {
    return `query MyQuery {
    allGlossaries(first: ${limit}, skip: ${skip}) {
      description {
        blocks
        links
        value
      }
      title
    }
  }`;
};

export const GET_ALL_DOCUMENTATIONS = (limit: number, skip: number) => {
    return `query MyQuery {
    allDocumentations(first: ${limit}, skip: ${skip})
    {
      ${DOCUMENTATION_BODY}
    }
}`;
};

export const GET_ALL_PRODUCT_PAGES = (limit: number, skip: number) => {
    return `query MyQuery {
  allProductPages(first: ${limit}, skip: ${skip}) {
    badge
    slug
    title
    keywords
    image
    id
    description
    category
    content {
      ... on ProductBenefitContainerRecord {
        __typename
        id
        title
        benefits {
          title
          ctaLink
          ctaText
          description
          icon {
            url
          }
        }
      }
      ... on ProductFeatureContainerRecord {
        __typename
        title
        features {
          ctaLink
          ctaText
          description
          featured
          title
          image {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              base64
              aspectRatio
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
          imageB {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
        }
      }
      ... on ProductGuideContainerRecord {
        __typename
        id
        guides {
          __typename
          title
          slug
          bannerimagegraphic {
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
        }
      }
      ... on ProductHeroRecord {
        __typename
        id
        backgroundImage {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
        }
        image {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
        }
        subtitle
        title
        ctaText
        ctaUrl
        cta2Text
        cta2Url
      }
      ... on ProductNumberSectionRecord {
        __typename
        id
        numbers {
          description
          title
          value
          id
        }
      }
      ... on ProductSocialProofRecord {
        __typename
        id
        category
      }
    }
  }
}
`;
};

export const GET_ALL_WEBSITE_PAGES = (limit: number, skip: number) => {
    return `query MyQuery {
  allWebsitePages(first: ${limit}, skip: ${skip}) {
    badge
    slug
    title
    keywords
    image
    id
    description
    category
    content {
      ... on ProductBenefitContainerRecord {
        __typename
        id
        title
        benefits {
          title
          ctaLink
          ctaText
          description
          icon {
            url
          }
        }
      }
      ... on TestimonialssectionRecord {
        __typename
        title
        hideSectionOnAdLp
        description
        testimonials {
          name
          title
          organization
          content
        }
      }
      ... on LargeImageSectionRecord {
        __typename
        title
        description
        image {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
        }
      }
      ... on HugeCtaSectionRecord {
        __typename
        id
        ctas {
          icon
          id
          secondary
          text
          url
        }
        title
        description
      }
      ... on ProductFeatureContainerRecord {
        __typename
        title
        features {
          ctaLink
          ctaText
          description
          featured
          title
          image {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              base64
              aspectRatio
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
          imageB {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
        }
      }
      ... on HighlightProductFeatureContainerRecord {
        __typename
        title
        features {
          ctaLink
          ctaText
          description
          featured
          title
          image {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              base64
              aspectRatio
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
          imageB {
            __typename
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
        }
      }
      ... on ProductGuideContainerRecord {
        __typename
        title
        subtitle
        id
        guides {
          __typename
          title
          slug
          bannerimagegraphic {
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
          }
        }
      }
      ... on ProductHeroRecord {
        __typename
        id
        backgroundImage {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            title
            webpSrcSet
            width
          }
        }
        image {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            title
            webpSrcSet
            width
          }
        }
        subtitle
        title
        ctaText
        ctaUrl
        cta2Text
        cta2Url
      }
      ... on ProductGoldrushSectionRecord {
        __typename
        title
        subtitle
        image {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            title
            webpSrcSet
            width
          }
        }
      }
      ... on ProductNumberSectionRecord {
        __typename
        id
        numbers {
          description
          title
          value
          id
        }
      }
      ... on ProductSocialProofRecord {
        __typename
        id
        category
      }
    }
  }
}
`;
};

export const GET_ALL_BLOGS = (limit: number, skip: number) => {
    return `query MyQuery {
      allBlogs(orderBy: date_DESC, first: ${limit}, skip: ${skip}) {
        author {
          id
          name
          slug
          title
          headshot {
            id
            responsiveImage(imgixParams: {fit: crop, auto: format, h: 60, w: 60}) {
              alt
              base64
              bgColor
              title
              width
              src
              height
              sizes
            }
          }
        }
        seo {
            description
            title
        }
        title
        _publishedAt
        date
        category
        slug
        content {
          value
          blocks {
            __typename
            ... on BlogimageRecord {
              id
              image {
                responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
                  aspectRatio
                  base64
                  height
                  sizes
                  src
                  width
                  alt
                  title
                }
              }
            }
            ... on YoutubeRecord {
              id
              youtube {
                url
                title
                thumbnailUrl
              }
            }
            ... on TableRecord {
              id
              content
            }
            ... on BloggalleryRecord {
              id
              gallery {
                responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
                  aspectRatio
                  base64
                  height
                  sizes
                  src
                  width
                  alt
                  title
                }
              }
            }
          }
        }
        bannerimage {
          url
          responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
            aspectRatio
            base64
            height
            sizes
            src
            width
            alt
            title
          }
        }
        
      }
  }`;
};

export const PRESS_PAGE = `
query MyQuery {
pressPage {
  featured {
    bannerImage {
      responsiveImage(imgixParams: {fit: crop,  auto: format}) {
        alt
        aspectRatio
        base64
        bgColor
        height
        sizes
        src
        
        title
        webpSrcSet
        width
      }
    }
    title
    url
    mediaOutlet
    language
    id
    date
  }
  podcastContainer {
    description
    title
    url
    participant {
      id
      name
      slug
      title
      headshot {
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
      }
    }
    id
  }
}
}
`;

export const GET_ALL_PRESSES = (limit: number, skip: number) => {
    return `query MyQuery {
    allPresses(first: ${limit}, skip: ${skip}) {
      date
      language
      mediaOutlet
      bannerImage {
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          
          src
          title
          webpSrcSet
          width
        }
      }
      homepageshowcase
      title
      url
      id
    }
  }`;
};

export const GET_ALL_IMAGES = (limit: number, skip: number) => {
    return `query MyQuery {
      allUploads(first: ${limit}, skip: ${skip}) {
        filename
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          aspectRatio
          alt
          base64
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
        basename
    }
  }`;
};

const DOCUMENTATION_BODY = `
id
noContent
title
createdAt
category
content {
  blocks {
    __typename
    ... on ApiendpointcardRecord {
      id
      updatedAt
      endpoint {
        description(markdown: false)
        title
        slug
      }
    }
    ... on CalloutRecord {
      id
      title
      content
      ctype
    }
    ... on PdfRecord {
      id
      title
      file {
        url
        filename
        title
      }
    }
    ... on ImageGridContainerRecord {
      __typename
      id
      grid {
        __typename
        url
        image {
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
        }
      }
    }
    ... on AccordionRecord {
      id
      content {
        blocks
        links
        value
      }
      title
    }
    ... on ClassCEndpointFeatureSectionRecord {
      id
      image {
        alt
        author
        basename
        url
        title
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
      }
      endpoint {
        samplewallet
        path
        params
        notes {
          value
          links
          blocks
        }
        noteType
        name
        id
        endpointtype
        description
        createdAt
        application {
          slug
          imgwhite {
            url
            title
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              width
              webpSrcSet
              title
              
              src
              sizes
              height
              bgColor
              base64
              aspectRatio
              alt
            }
            notes
            filename
            basename
            alt
          }
          id
          displayname
          description
          categoryTags {
            name
            id
          }
          blockchains {
            testnetchainof {
              chainname
              chainid
            }
            id
            displayname
            chainname
            chainid
          }
        }
      }
      description
    }
    ... on CodesamplesectionRecord {
      id
      title
      description(markdown: false)
    }
    ... on SignupCtaRecord {
      id
      ctaType
    }
    ... on CardGroupRecord {
      id
      __typename
      cards {
        __typename
        description {
          blocks
          links
          value
        }
        icon
        id
        title
        url
        image {
          alt
          basename
          filename
          height
          size
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            alt
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
          thumbhash
          title
          url
        }
      }
    }
    ... on DocsCardRecord {
      __typename
      id
      createdAt
      description {
        blocks
        links
        value
      }
      icon
      title
      url
      image {
        alt
        basename
        filename
        height
        size
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
        thumbhash
        title
        url
      }
    }
    ... on StepContainerRecord {
      id
      steps {
        content {
          blocks
          links
          value
        }
        id
        sideCode
        codeLanguage
        stepNumber
        sideImage {
          alt
          basename
          filename
          responsiveImage(imgixParams: {fit: crop,  auto: format}) {
            aspectRatio
            base64
            bgColor
            height
            sizes
            src
            
            title
            webpSrcSet
            width
          }
          title
        }
        title
      }
    }
    ... on DocsImageRecord {
      id
      whitespace
      gradient
      image {
        alt
        basename
        filename
        height
        size
        responsiveImage(imgixParams: {fit: crop,  auto: format}) {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          
          title
          webpSrcSet
          width
        }
        thumbhash
        title
        url
      }
    }
    ... on DocumentSectionRecord {
      id
      theme
      title
      description(markdown: false)
      documents {
        documentUrl
        fileType
        id
        name
        url
        document {
          basename
          filename
          url
          title
        }
      }
    }
    ... on SdkInstallerRecord {
      id
      manager
      script
    }
    ... on TableRecord {
      id
      content(markdown: false)
    }
    ... on SdkExampleRecord {
      id
      show
    }
  }
  links
  value
}
seo {
  description
  title
  twitterCard
  image {
    width
    url
    title
  }
}
slug
updatedAt
`;

const CHANGELOG_BODY = ` 
title
slug
cta
bannerimagegraphic {
  url
}
image {
  url
  responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 750, auto: format}) {
    aspectRatio
    base64
    height
    sizes
    src
    
    width
    alt
    title
  }
}

date    
description    
product    `;

export const GET_ALL_CHANGELOG = `
query MyQuery {
  allChangelogs(first: 100, orderBy: date_DESC) {
    ${CHANGELOG_BODY}
  }
}`;

export const GET_DATO_LP = `
query LPBySlug($slug: String) {
    landingPage(filter: {slug: {eq: $slug}}) {
        ${LP_BODY}
    }     
  }
`;

export const GET_DATO_DOCUMENTATION = `
query DocumentationBySlug($slug: String, $category: String) {
    documentation(filter: {AND: {slug: {eq: $slug}, category: {eq: $category}}}) {
        ${DOCUMENTATION_BODY}
    }     
  }
`;


export const GET_DATO_BLOG = `
query PostBySlug($slug: String) {
  blog(filter: {slug: {eq: $slug}}) {
    seo {
        description
        title
    }
    author {
        id
        name
        slug
        title
        headshot {
          id
          responsiveImage(imgixParams: {fit: crop, auto: format, h: 60, w: 60}) {
            alt
            base64
            bgColor
            title
            width
            src
            height
            sizes
          }
        }
      }
      title
      _publishedAt
      _updatedAt
      date
      category
      slug
      content {
        value
        blocks {
          __typename
          ... on BlogimageRecord {
            id
            image {
              responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
                aspectRatio
                base64
                height
                sizes
                src
                
                width
                alt
                title
              }
            }
          }
          ... on YoutubeRecord {
            id
            youtube {
              url
              title
              thumbnailUrl
              providerUid
            }
          }
          ... on TableRecord {
            id
            content
          }
          ... on BloggalleryRecord {
            id
            gallery {
              responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
                aspectRatio
                base64
                height
                sizes
                src
                
                width
                alt
                title
              }
            }
          }
          ... on RecordingRecord {
            id
            recording {
              __typename
              id
              url
            }
          }
        }
      }
      bannerimage {
        url
        responsiveImage(imgixParams: {fit: crop, ar: "16:9", w: 800, auto: format}) {
          aspectRatio
          base64
          height
          sizes
          src
          
          width
          alt
          title
        }
      }
  }
}`;

export const GET_DATO_SLUG = `
query MyQuery {
    allBlogs {
        slug
      }
}`;

export const GET_PLATFORM_BANNER = `
query MyQuery {
  platformBanner {
    show
    bannerMessage {
      blocks
      links
      value
    }
  }
}`;

const ECOSYSTEM_BODY = `
  category
  description
  name
  showcase
  imgwhite {
    responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
      sizes
      src
      
      title
      webpSrcSet
      width
      alt
      aspectRatio
      base64
      bgColor
      height
    }  
  }
  prime
  url
  product
`;

export const GET_ALL_ECOSYSTEMS = `
query MyQuery {
  allEcosystems(first:100) {
    ${ECOSYSTEM_BODY}
  }
}`;


const NETWORKS_BODY = `
id
displayname
_status
chainname
chainid
testnet
description
blocktime
blockexplorer
historicalBalances
nftAssetsAndMetadata
phantom
nativegastoken
docsException
weight
hide
url
nativegastoken
suspended
increment
integrationstage {
  stagesupport {
    classA
    classB
    classC
    humanDecodedTransactions
    nftAssetAndMetadataCache
    tokenHolders
    traces
  }
  stage
}
notes {
  value
  blocks
  links
}
appchainof {
  chainname
}
testnetchainof {
  chainname
}
imgsvg {
  url
}
imgblack {
  url      
}
notes {
  blocks
  links
  value
}
noteType
imgwhite {
  responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
    src
    width
    height
    alt
    title
    base64
    bgColor
    sizes
  }      
}`;


export const GET_ALL_NETWORKS = (limit, skip) => `
query MyQuery {
  allBlockchains(orderBy: weight_ASC, first: ${limit}, skip: ${skip}) {
    ${NETWORKS_BODY}
  }
}`;

export const GET_DATO_NETWORK = `
query PostBySlug($slug: String) {
  blockchain(filter: {displayname: {eq: $slug}}) {
    ${NETWORKS_BODY}
  }
}`;
const GUIDES_BODY = `
_status
  _publishedAt
  _firstPublishedAt      
  bannerimagegraphic {
    url
    responsiveImage(imgixParams: {fit: crop,  auto: format}) {
      aspectRatio
      base64
      bgColor
      height
      sizes
      src
      
      title
      webpSrcSet
      width
    }
  }
  title
  grouping
  revealed
  slug        
  author {
    id
    name
    slug
    title
    headshot {
      id
      responsiveImage(imgixParams: {fit: crop, auto: format, h: 60, w: 60}) {
        alt
        base64
        bgColor
        title
        width
        src
        height
        sizes
      }
    }
  }
  endpoints {
    ... on ApiendpointRecord {
      __typename
      slug
      title
      grouping            
    }
    ... on ClasscEndpointRecord {
      __typename
      application {
        displayname
        slug
      }
      name
      description
      endpointtype
    }
  }
  seo {
      title 
      description
  }
  content {
      value
      links {
          __typename
          ... on ApiendpointRecord {
              id
              title
              slug
              usecase
              description
              usecase
          }
      }
      blocks {
          __typename
          ... on BlogimageRecord {
            id 
            image {
              url
              responsiveImage(imgixParams: {fit: crop, auto: format}) {
                  src
                  width
                  height
                  alt
                  title
                  base64
                  bgColor
                  sizes           
              }
            }        
          }
          ... on TableRecord {
              id
              content
          }
          ... on StepContainerRecord {
            id
            steps {
              content {
                blocks
                links
                value
              }
              id
              sideCode
              codeLanguage
              stepNumber
              sideImage {
                alt
                basename
                filename
                responsiveImage(imgixParams: {fit: crop,  auto: format}) {
                  aspectRatio
                  base64
                  bgColor
                  height
                  sizes
                  src
                  
                  title
                  webpSrcSet
                  width
                }
                title
              }
              title
            }
          }
          ... on CalloutRecord {
              id
              title
              content
              ctype
          }
          ... on ApiendpointcardRecord {
              id
              endpoint {
                  id
                  title
                  slug
                  usecase
                  description
                  usecase
              }
          }
          ... on BloggalleryRecord {
              id 
              gallery {
                  filename 
                  url 
                  responsiveImage(imgixParams: {fit: crop, auto: format}) {
                      src
                      width
                      height
                      alt
                      title
                      base64
                      bgColor
                      sizes           
                  }    
              }
            }
          }
      }
`;

export const GET_ALL_GUIDES = (limit, skip) => `
query MyQuery {
allGuides(orderBy: grouping_DESC, first: ${limit}, skip: ${skip}) {
  ${GUIDES_BODY}
  }
}`;

export const GET_DATO_GUIDE = `
query PostBySlug($slug: String) {
  guide(filter: {slug: {eq: $slug}}) {
    ${GUIDES_BODY}
  }
}`;

const CLASSC_BODY = `
application {
  slug
  _status
  displayname
  description
  blockchains {
    chainname
    imgwhite {
      responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
        src
        width
        height
        alt
        title
        base64
        bgColor
        sizes
      }
    }
  }
  imgwhite {
    responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
      src
      width
      height
      alt
      title
      base64
      bgColor
      sizes
    }
  }
}
notes {
  blocks
  links
  value
}
noteType
description
samplewallet
endpointtype
params
path
name
`;

export const GET_ALL_CLASSC = `
query MyQuery {
  allClasscEndpoints(first: 100) {
    ${CLASSC_BODY}
  }
}`;

export const GET_DATO_CLASSC = `
query PostBySlug($name: String) {
  classcEndpoint(filter: {name: {eq: $name}}) {
    ${CLASSC_BODY}
  }
}`;

const ENDPOINTS_BODY = `
  id
  _status
  class
  phantom
  realtime
  slug
  title
  grouping
  _allReferencingGuides {
    slug
    title
    seo {
      description
    }      
  }
  children {
    id
  }
  supportedchains {
    chainname
    chainid
  }
  allChainsSupported
  creditrate
  creditratemodel
  creditrateunit
  usecase
  description
  beta
  notes {
    blocks
    links
    value
  }
  noteType
  typescriptSdkSupport
`;


export const GET_ALL_ENDPOINTS = (limit, skip) => {
    return `
query MyQuery {
  allApiendpoints(first: ${limit}, skip: ${skip}) {
    ${ENDPOINTS_BODY}
  }
}`;
};

export const GET_DATO_ENDPOINT = `
query PostBySlug($slug: String) {
  apiendpoint(filter: {slug: {eq: $slug}}) {
    ${ENDPOINTS_BODY}
  }
}`;


const API_REF_BODY = `
  classA {
    blocks
    links
    value
  }
  classB {
    blocks
    links
    value
  }
  classC {
    blocks
    links
    value
  }
  overview {
    blocks {
      __typename
      ... on TableRecord {
          id
          content
      }
      ... on AccordionRecord {
        id
        content {
          blocks
          links
          value
        }
        title
      }
      ... on CalloutRecord {
        id
        content
        ctype
        title
      }
      ... on CardGroupRecord {
        id
        cards {
          description {
            blocks
            links
            value
          }
          icon
          id
          title
          url
          image {
            alt
            basename
            filename
            height
            size
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
            thumbhash
            title
            url
          }
        }
      }
      ... on SideBySideRecord {
        id
        left {
          links
          value
          blocks {
            __typename
            title
            id
            content {
              blocks
              links
              value
            }
          }
        }
        right {
          blocks {
            __typename
            content
            id
          }
          links
          value
        }
      }
      ... on StepContainerRecord {
        id
        steps {
          content {
            blocks
            links
            value
          }
          id
          sideCode
          sideImage {
            responsiveImage(imgixParams: {fit: crop,  auto: format}) {
              alt
              aspectRatio
              base64
              bgColor
              height
              sizes
              src
              
              title
              webpSrcSet
              width
            }
            title
            url
          }
          stepNumber
          title
          updatedAt
        }
      }
    }
    links
    value
  }
`;

export const GET_ALL_API_REF_GUIDE = `
query MyQuery {
  apiReferenceGuide {
    ${API_REF_BODY}
  }
}`;
const DIAGRAM_BODY = `
caption
displayname
image {
  responsiveImage(imgixParams: {fit: crop, auto: format}) {
    src
    width
    height
    alt
    title
    base64
    bgColor
    sizes           
  } 
}
product
slug
`;

export const GET_DATO_DIAGRAM = `
query PostBySlug($slug: String) {
  diagram(filter: {slug: {eq: $slug}}) {
      ${DIAGRAM_BODY}
    }
  }
`;

const DOCUMENT_BODY = `
  slug
  displayname
  caption
  product
  document {
    id
    mimeType
    size
    url
  }
`;

export const GET_DATO_DOCUMENT = `
query PostBySlug($slug: String) {
  document(filter: {slug: {eq: $slug}}) {
      ${DOCUMENT_BODY}
    }
  }
`;

export const INTEGRATION_BODY = `
stage
stagesupport {
  classA
  classB
  classC
  humanDecodedTransactions
  increment
  nftAssetAndMetadataCache
  tokenHolders
  traces
}
`;

export const GET_ALL_INTEGRATIONS = `
query MyQuery {
  allIntegrationstages {
    ${INTEGRATION_BODY}
  }
}`;

export const GRK_BODY = `
component
slug
image {
  responsiveImage(imgixParams: {fit: crop,  auto: format}) {
    aspectRatio
    alt
    base64
    bgColor
    height
    sizes
    src
    
    title
    webpSrcSet
    width
  }
}
codeSample {
  blocks
  links
  value
}
title
subtitle
updatedAt
description
createdAt
`;

export const GET_ALL_GRK = `query MyQuery {
  allGoldrushcomponents {
    component
    slug
    subtitle
    title
    updatedAt
    description
    codeSample {
      blocks
      links
      value
    }
    image {
      responsiveImage {
        width
        webpSrcSet
        title
        srcSet
        src
        sizes
        height
        bgColor
        base64
        aspectRatio
        alt
      }
    }
  }
}`;

export const GET_GRK = `
query PostBySlug($slug: String) {
  goldrushcomponent(filter: {slug: {eq: $slug}}) {
    ${GRK_BODY}
  }
}`;

export const GET_DATO_SDKS = `
query SDKs {
  allSdks {
    installers {
      manager
      script
    }
    language
    installName
    version
  }
}
`;

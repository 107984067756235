
interface datoProps { 
    img: string;
}

export default function GenericTemplate (props: datoProps) {
    
    return <div
        style={{
            height: "100vh",
            width:"100%",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingBottom: "4px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            fontWeight: "400",
            color: "white",
        }}
    >
        <div style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", gap: "1rem", position:"relative"}}>
            <img width={"1920px"} height={"1080px"} style={{position: "absolute"}} src={"https://www.datocms-assets.com/86369/1674771072-covalent-13.jpg"}></img>
            <img width={"900px"} height={"900px"} style={{ zIndex: "100", borderRadius: "30px"}} src={props.img}></img>
        </div>
    </div>;
}
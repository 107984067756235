import React from "react";
import { useRouter } from "next/router";
import { TabNav } from "@radix-ui/themes";

const TopNav: React.FC = () => {
    const router = useRouter();
    const topRouteParts = router.pathname.split("/"), topRoute = `/${topRouteParts[1]}${topRouteParts[2] ? "/" + topRouteParts[2] + "/" + topRouteParts[3] : ""}`;
    const routerPath = router.asPath;
    const navLinks: any = [];

    if (topRouteParts[1] === "blog") {
        navLinks.push({
            title: "Blog Home",
            href: "/blog/"
        });
    } else if (topRouteParts[1] === "docs") {
        navLinks.push(...[{
            title: "Docs Home",
            href: "/docs/"
        },
        {
            title: "GoldRush API",
            href: "/docs/unified-api/"
        },
        {
            title: "API Reference",
            href: "/docs/api/"
        },
        {
            title: "Supported Networks",
            href: "/docs/networks/"
        },
        {
            title: "Guides",
            href: "/docs/unified-api/guides/"
        },
        ]);
    }


    return (<TabNav.Root size="2">
        { navLinks.map((navLink: { title: string, href: string}, index) => {

            const isGuide = router.pathname.includes("guide");
            const isCurrentRoute = routerPath === navLink.href;
            const isTopRouteUnderNavLink = (navLink.href !== "/docs/" && navLink.href !== "/blog/") && topRoute.startsWith(navLink.href);

            let active = false;
                        
            if (isGuide) {
                active = navLink.title === "Guides";
            } else {
                active = isCurrentRoute || isTopRouteUnderNavLink;
            }

            return (<TabNav.Link key={navLink.title + index} href={navLink.href} active={active}>
                {navLink.title}
            </TabNav.Link>);
        })}
    </TabNav.Root>);
};


export default TopNav;